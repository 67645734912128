input[type='radio'] {
  transform: scale(2, 2);
  -moz-transform: scale(2, 2);
  -ms-transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  -o-transform: scale(2, 2);
  min-width: 10px;
  max-width: 10px;
  margin: 0 5px 0 0
}
